import Config from "../config";
import { HashLink } from 'react-router-hash-link';

export default function Footer(){
    return (
        <footer id="footer">
            <div className="container">
                <div className="logo text-center"><img src="/assets/images/misc/logov2.png?v=1" alt="Logo"/></div>
                <div className="menu">
                    <ul className="column">
                        <li><a href="/" title="">Home</a></li>
                        <li><HashLink to="/#home-features" title="">Features</HashLink></li>
                        <li><HashLink to="/#home-faq" title="">FAQ</HashLink></li>
                    </ul>
                    <ul className="column">
                        <li><a href="/terms-of-use" title="">Terms of Use</a></li>
                        <li><a href="/privacy-policy" title="">Privacy Policy</a></li>
                        <li><a href="/cookies-policy" title="">Cookies Policy</a></li>
                    </ul>
                    <ul className="column">
                        <li><a href="/contact-us" title="">Contact Us</a></li>
                        <li><a href="/dmca-policy" title="">DMCA Policy</a></li>
                        <li><a href="/software-policy" title="">Software Policy</a></li>
                    </ul>
                </div>
                <div className="socials">
                    <a href="#" className="facebook" title=""><i className="bi bi-facebook"></i></a>
                    <a href="#" className="linkedin" title=""><i className="bi bi-linkedin"></i></a>
                    <a href="#" className="instagram" title=""><i className="bi bi-instagram"></i></a>
                    <a href="#" className="youtube" title=""><i className="bi bi-youtube"></i></a>
                </div>
                <div className="copyright">
                    <p className="mb-3">© {new Date().getFullYear()} {Config.companyName}. All rights reserved.</p>
                    <p><strong>Address:</strong> {Config.address}</p>
                    <p><strong>Email:</strong> {Config.email}</p>
                    <p><strong>Phone:</strong> {Config.phone}</p>
                </div>
            </div>
        </footer>
    )
}
