
import {Route, Routes} from 'react-router-dom';
import Test from "./controllers/Test";
import Home from "./controllers/Home";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Article from "./controllers/Article";
import Category from "./controllers/Category";
import Search from "./controllers/Search";
import ScrollToTop from "./components/ScrollToTop";
import TermsOfUse from "./controllers/Page/TermsOfUse";
import NotFound from "./controllers/Page/NotFound";
import PrivacyPolicy from "./controllers/Page/PrivacyPolicy";
import CookiesPolicy from "./controllers/Page/CookiesPolicy";
import ContactUs from "./controllers/Page/ContactUs";
import DmcaPolicy from "./controllers/Page/DmcaPolicy";
import SoftwarePolicy from "./controllers/Page/SoftwarePolicy";

function App() {
    return (
        <div className="App">
            <ScrollToTop />
            <Navbar/>
            <main id="main">
                <Routes>
                    <Route index path='/' element={< Home/>}/>
                    <Route exact path='/category/:slug' element={< Category/>}/>
                    <Route exact path='/search' element={<Search/>}/>
                    <Route path="/product/:slug" element={<Article />}/>
                    <Route exact path='/terms-of-use' element={<TermsOfUse/>}/>
                    <Route exact path='/privacy-policy' element={<PrivacyPolicy/>}/>
                    <Route exact path='/cookies-policy' element={<CookiesPolicy/>}/>
                    <Route exact path='/dmca-policy' element={<DmcaPolicy/>}/>
                    <Route exact path='/software-policy' element={<SoftwarePolicy/>}/>
                    <Route exact path='/contact-us' element={<ContactUs/>}/>
                    <Route path='*' element={<NotFound />}/>
                </Routes>
            </main>
            <Footer/>
        </div>
    );
}

export default App;
