import Config from "../../config";

export default function DmcaPolicy() {
    document.title = 'DMCA Policy';
    return (
        <div className='container'>
            <div className="page-title-header">
                <h1 className="page-title">DMCA Policy</h1>
            </div>
            <section className="section page-section">
                <div className='mb-5'>
                    <p>We respects the intellectual property rights of others. In accordance with the Digital Millennium Copyright Act of 1998, we will respond expeditiously to claims of copyright infringement committed using our website (the “Site”) if such claims are reported to our Designated Copyright Agent as described below.</p>
                    <p>If you are a copyright owner, authorized to act on behalf of one, or authorized to act under any exclusive right under copyright, please report alleged copyright infringements taking place on or through the Site by completing the following DMCA Notice of Alleged Infringement and delivering it to our Designated Copyright Agent. Upon receipt of a notice as described below, we will take whatever action it deems appropriate within its sole discretion, including removal of the allegedly infringing content from the Site.</p>
                    <p>If you believe that your intellectual property rights have been violated by us, please provide the following information to the designated Copyright Agent listed below:</p>
                    <ol>
                        <li>A description of the copyrighted work or other intellectual property that you claim has been infringed;</li>
                        <li>A description of where the material that you claim is infringing is located on the Site;</li>
                        <li>An address, telephone number, and email address where we can contact you and, if different, an email address where the alleged infringing party, if not we, can contact you;</li>
                        <li>A statement that you have a good-faith belief that the use is not authorized by the copyright owner or other intellectual property rights owner, by its agent, or by law;</li>
                        <li>A statement by you under penalty of perjury that the information in your notice is accurate and that you are the copyright or intellectual property owner or are authorized to act on the owner’s behalf;</li>
                        <li>Your electronic or physical signature.</li>
                    </ol>
                    <p>We may request additional information before removing any allegedly infringing material.</p>
                    <p>We may revise this policy at any time, including by posting a new version at this website.</p>
                    <p>Please submit a DMCA notice to: <a href={`mailto://dmca@${Config.domain}`}>dmca@{Config.domain}</a> </p>
                </div>
            </section>
        </div>
    )
}