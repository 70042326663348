let domain =  window.location.hostname;
const mainDomain = 'media.'+window.location.hostname;
const Config = {
    apiDomain:"https://"+mainDomain+"/api/",
    domain: domain,
    fullDomain: 'https://' + domain,
    companyName: 'Blue Softs Company',
    address: '7315 Galvin Rd, El Monte, California, USA 91732',
    email: 'support@' + domain,
    phone: '+1(559) 346-5982',

}
export default Config
