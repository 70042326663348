import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => (
    <div className="text-center">
        <div className='my-5'>
            <img src="assets/images/misc/404notfound.png" alt="not-found" />
        </div>
        <Link to="/" className="btn btn-lg btn-primary">
            Go Home
        </Link>
    </div>
);

export default NotFound;