import React, {useEffect, useState} from "react";
import {Link, useParams, useSearchParams} from "react-router-dom";
import Config from "../config";
import ReactPaginate from 'react-paginate';
import Skeleton from 'react-loading-skeleton'

function articleItem(){
    return (
        <div className="col-lg-4">
            <div className="item" style={{width: '100%'}}>
                <div className="top">
                    <Skeleton className="image" width={'80px'} height={'80px'} />
                    <div className="info mt-1" style={{width: '100%'}}>
                        <h3 className="title"><Skeleton /></h3>
                        <span className="count"><Skeleton /></span>
                    </div>
                </div>
                <div className="bottom">
                    <div className="description mb-2"><Skeleton count={3} /></div>
                    <div className="action"><Skeleton height={'31px'} /></div>
                </div>
            </div>
        </div>
    )
}

export default function Search() {
    const {slug} = useParams();
    const [searchParams] = useSearchParams();
    const [pagingInfo, setPagingInfo] = useState({current: searchParams.get("page") || 1, range: 5, count: 1});
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    useEffect(() => {
        loadData();
    }, [slug]);

    const [object, setObject] = useState([]);
    const [noresult, setNoresult] = useState(false);
    const loadData = () => {
        fetch(Config.apiDomain + "article/search?q=" + (searchParams.get("q")) + '&page=' + (pagingInfo.current))
            .then((res) => res.json())
            .then((json) => {
                setObject(json.data);
                if(json.data.length === 0) setNoresult(true);
                const opts = json.optional;
                setPagingInfo({...pagingInfo, current: opts.paging.page, count: opts.paging.totalPage});
                document.title = 'Search: ' + searchParams.get("q");
            }).finally(() => {
            setIsFirstLoad(false);
        });
    }
    const handlePageClick = (event) => {
        if (!isFirstLoad) {
            let newObj = pagingInfo;
            newObj.current = event.selected + 1;
            setPagingInfo(newObj)
            loadData();
            const currentUrl = new URL(window.location.href);
            currentUrl.searchParams.set('page', newObj.current.toString());
            window.history.replaceState({}, '', currentUrl.href);
        }
    };

    return (
        <div className="container">
            <div className="page-title-header">
                <h1 className="page-title">Search: {searchParams.get("q")}</h1>
            </div>

            <section className="section page-section">
                {object.article ? (
                    <div className="category-soft-list row gx-4 gy-4">
                        {object.article && object.article.map((item, idx) => (
                            <div className="col-lg-4" key={idx}>
                                <Link to={item.link} className="item">
                                    <div className="top">
                                        <img className="image" src={item.avatar} alt={item.title}/>
                                        <div className="info">
                                            <h3 className="title">{item.title}</h3>
                                            <span className="count">{item.download_count} downloads</span>
                                        </div>
                                    </div>
                                    <div className="bottom">
                                        <div className="description">{item.description}</div>
                                        <div className="action"><span className="btn btn-primary btn-block btn-sm">View Details</span></div>
                                    </div>
                                </Link>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className="category-soft-list row gx-4 gy-4">
                        { noresult === true ? (
                            <div className='text-center'>
                                <div className='mb-3 text-danger'>
                                    No result!
                                </div>
                                <div>
                                    <Link to="/" className="btn btn-primary">
                                        Go Home
                                    </Link>
                                </div>
                            </div>
                        ) : (
                            <div>
                                { articleItem() }
                                { articleItem() }
                                { articleItem() }
                                { articleItem() }
                                { articleItem() }
                                { articleItem() }
                                { articleItem() }
                                { articleItem() }
                                { articleItem() }
                                { articleItem() }
                                { articleItem() }
                                { articleItem() }
                            </div>
                        )}
                    </div>
                )}
            </section>
            {object.article ? (
                <ReactPaginate
                    breakLabel="..."
                    nextLabel=">"
                    onPageChange={(p) => {
                        handlePageClick(p);
                    }}
                    initialPage={pagingInfo.current - 1}
                    pageRangeDisplayed={pagingInfo.range}
                    pageCount={pagingInfo.count}
                    previousClassName={"page-item"}
                    nextClassName={"page-item"}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                    className={"pagination pagination-custom justify-content-center"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousLinkClassName={"page-link"}
                    nextLinkClassName={"page-link"}
                    activeClassName={"active"}
                />
            ) : (
                <Skeleton height={ noresult === true ? '0px' : '42px'} />
            )}
        </div>
    );
}
