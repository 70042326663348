import Config from "../../config";

export default function ContactUs() {
    document.title = 'Contact Us';
    return (
        <div className='container'>
            <div className="page-title-header">
                <h1 className="page-title">Contact Us</h1>
            </div>
            <section className="section page-section">
                <div className='mb-5'>
                    <p>You can contact us via:</p>
                    <p><strong>Address:</strong> {Config.address}</p>
                    <p><strong>Email:</strong> {Config.email}</p>
                    <p><strong>Phone:</strong> {Config.phone}</p>
                </div>
            </section>
        </div>
    )
}