import {useEffect, useState} from "react";
import {Link, useParams, useSearchParams} from "react-router-dom";
import Config from "../config";
import Skeleton from 'react-loading-skeleton'
import { Tooltip } from 'react-tooltip'

function articleItem(){
    return (
        <div className="col-lg-12">
            <div className="item download_btn" style={{width: '100%'}}>
                <div className="top">
                    <Skeleton className="image" width={'55px'} height={'55px'} />
                    <div className="info mt-1" style={{width: '100%'}}>
                        <h3 className="title"><Skeleton /></h3>
                        <span className="count"><Skeleton /></span>
                    </div>
                </div>
                <div className="bottom">
                    <div className="description mb-2"><Skeleton count={3} /></div>
                    <Skeleton height={'31px'} />
                </div>
            </div>
        </div>
    )
}

export default function Article() {
    const [searchParams] = useSearchParams();
    const [ns, setNs] = useState(searchParams.get("ns") ? searchParams.get("ns") : '');
    const {slug} = useParams();
    useEffect(() => {
        loadData(slug)
    }, [slug]);


    const [object, setObject] = useState({category: {}, default_value: {}, related_articles: [], popular_articles: []});
    const [activeTab, setActiveTab] = useState('information');

    const loadData = (_slug)=>{
        let fullDomain = Config.apiDomain;
        fetch(Config.apiDomain + "article/details/" + _slug + '?ns=' + ns)
            .then((res) => res.json())
            .then((json) => {
                if(json.data){
                    setObject(json.data);
                    document.title = json.data.title;
                }

            })
            .catch(error => {
                window.location.assign("/");
            })
        ;
    }

    const [downloadText, setDownloadText] = useState('Download Now');
    const [downloadClass, setDownloadClass] = useState('');
    function downloadFile(download_url){
        window.location.href = download_url;
        setDownloadText('Downloading...');
        setDownloadClass('downloading-btn');
        setTimeout(
            function() {
                setDownloadText('Download Now');
                setDownloadClass('');
            }
                .bind(this),
            1000
        );
    }

    const [isFixedDownload, setIsFixedDownload] = useState(false);
    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.pageYOffset;
            if (scrollTop > 100) {
                setIsFixedDownload(true);
            } else {
                setIsFixedDownload(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className="container">
            <Tooltip anchorSelect=".tooltip_other_languages" place="top" style={{width: '300px', zIndex: 9999}}>
                <p className="fw-bold">Available languages:</p>
                <ul className="languages-list">
                    <li>English</li>
                    <li>Arabic</li>
                    <li>Czech</li>
                    <li>Danish</li>
                    <li>German</li>
                    <li>Greek</li>
                    <li>Spanish</li>
                    <li>Finnish</li>
                    <li>French</li>
                    <li>Italian</li>
                    <li>Japanese</li>
                    <li>Korean</li>
                    <li>Dutch</li>
                    <li>Norwegian</li>
                    <li>Polish</li>
                    <li>Portuguese</li>
                    <li>Swedish</li>
                    <li>Turkish</li>
                    <li>Chinese</li>
                    <li>Thai</li>
                    <li>Bengali</li>
                    <li>Urdu</li>
                    <li>Hindi</li>
                </ul>
            </Tooltip>
            <section id="soft-details">
                { object.category.title ? (
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/" title="Homepage">Home</Link></li>
                        <li className="breadcrumb-item"><Link to={object.category.link} title={object.category.title}>{object.category.title}</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">{object.title}</li>
                    </ol>
                </nav>
                ) : (
                    <Skeleton width={"300px"} height={"24px"} />
                )}
                <div className="row gx-0 mt-4">
                    <div className="col-lg-8 col-left">
                        <div id="header-details" className="d-flex align-items-center justify-content-between">
                            <div>
                                <div className="info-wrapper d-flex flex-row">
                                    { object.avatar ? (
                                        <img className="image" src={object.avatar} alt=""/>
                                    ) : (
                                        <Skeleton width={"80px"} height={"80px"} />
                                    )}
                                    <div className="title-wrapper">
                                        <h1 className="title">{object.title || <Skeleton width={"200px"} />}</h1>
                                        <div className="clearfix star-rating">
                                            { object.star_point ? (
                                            <div className="stars" data-bs-toggle="tooltip" title={`${object.star_point} / 5 Stars`}>
                                                <svg width="20" height="20" viewBox="0 0 940.688 940.688">
                                                    <path
                                                        d="M885.344,319.071l-258-3.8l-102.7-264.399c-19.8-48.801-88.899-48.801-108.6,0l-102.7,264.399l-258,3.8
		c-53.4,3.101-75.1,70.2-33.7,103.9l209.2,181.4l-71.3,247.7c-14,50.899,41.1,92.899,86.5,65.899l224.3-122.7l224.3,122.601
		c45.4,27,100.5-15,86.5-65.9l-71.3-247.7l209.2-181.399C960.443,389.172,938.744,322.071,885.344,319.071z"
                                                    />
                                                </svg>
                                                &nbsp;
                                                <svg width="20" height="20" viewBox="0 0 940.688 940.688">
                                                    <path
                                                        d="M885.344,319.071l-258-3.8l-102.7-264.399c-19.8-48.801-88.899-48.801-108.6,0l-102.7,264.399l-258,3.8
		c-53.4,3.101-75.1,70.2-33.7,103.9l209.2,181.4l-71.3,247.7c-14,50.899,41.1,92.899,86.5,65.899l224.3-122.7l224.3,122.601
		c45.4,27,100.5-15,86.5-65.9l-71.3-247.7l209.2-181.399C960.443,389.172,938.744,322.071,885.344,319.071z"
                                                    />
                                                </svg>
                                                &nbsp;
                                                <svg width="20" height="20" viewBox="0 0 940.688 940.688">
                                                    <path
                                                        d="M885.344,319.071l-258-3.8l-102.7-264.399c-19.8-48.801-88.899-48.801-108.6,0l-102.7,264.399l-258,3.8
		c-53.4,3.101-75.1,70.2-33.7,103.9l209.2,181.4l-71.3,247.7c-14,50.899,41.1,92.899,86.5,65.899l224.3-122.7l224.3,122.601
		c45.4,27,100.5-15,86.5-65.9l-71.3-247.7l209.2-181.399C960.443,389.172,938.744,322.071,885.344,319.071z"
                                                    />
                                                </svg>
                                                &nbsp;
                                                <svg width="20" height="20" viewBox="0 0 940.688 940.688">
                                                    <path
                                                        d="M885.344,319.071l-258-3.8l-102.7-264.399c-19.8-48.801-88.899-48.801-108.6,0l-102.7,264.399l-258,3.8
		c-53.4,3.101-75.1,70.2-33.7,103.9l209.2,181.4l-71.3,247.7c-14,50.899,41.1,92.899,86.5,65.899l224.3-122.7l224.3,122.601
		c45.4,27,100.5-15,86.5-65.9l-71.3-247.7l209.2-181.399C960.443,389.172,938.744,322.071,885.344,319.071z"
                                                    />
                                                </svg>
                                                &nbsp;
                                                <svg width="20" height="20" viewBox="0 0 940.688 940.688">
                                                    <path
                                                        d="M885.344,319.071l-258-3.8l-102.7-264.399c-19.8-48.801-88.899-48.801-108.6,0l-102.7,264.399l-258,3.8
		c-53.4,3.101-75.1,70.2-33.7,103.9l209.2,181.4l-71.3,247.7c-14,50.899,41.1,92.899,86.5,65.899l224.3-122.7l224.3,122.601
		c45.4,27,100.5-15,86.5-65.9l-71.3-247.7l209.2-181.399C960.443,389.172,938.744,322.071,885.344,319.071z"
                                                    />
                                                </svg>
                                                <div className="overlay" style={{width: object.percent_star_point + '%'}}></div>
                                            </div>
                                            ) : (
                                                <Skeleton width={"118px"} height={"24px"} />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                { object.content ? (
                                <span id="main-download-btn" onClick={() => downloadFile(object.download_url)} className={`${downloadClass} ${isFixedDownload ? 'fixed-download-btn' : ''} main_download_btn download-btn btn btn-primary btn-lg d-flex align-items-center justify-content-between`} title="Download">
                                    <div className="text text-start">
                                        <span className="main-text">{ downloadText }</span>
                                        <span className="sub-text">{object.download_count} downloads</span>
                                    </div>
                                    <div className="icon"><i className="bi bi-download"></i></div>
                                </span>
                                ) : (
                                    <Skeleton width={"300px"} height={"67px"} />
                                )}
                            </div>
                        </div>
                        { !object.content ? (
                            <div className='my-3'>
                                <Skeleton height={'40px'} />
                            </div>
                        ) : (
                            <ul className="menu-tab">
                                <li className={activeTab === 'information' ? 'active' : ''} onClick={() => setActiveTab('information')} style={{ width: object.how_to_install ? '50%' : '100%' }}>Information</li>
                                {object.how_to_install &&
                                <li className={activeTab === 'how_to_install' ? 'active' : ''} onClick={() => setActiveTab('how_to_install')} style={{ width: object.how_to_install ? '50%' : '100%' }}>How To Install</li>
                                }
                            </ul>
                        )}
                        <div id="details-tabs" className="menu-tab-item tab-infomation" style={{ display: activeTab === 'information' ? 'block' : 'none' }}>
                            <div className="caption">
                                { object.description || <Skeleton count={4} /> }
                            </div>
                            { !object.content ? (
                                <div className='mb-5'>
                                    <Skeleton count={5} />
                                </div>
                            ) : (
                            <ul className="properties">
                                <li><span className="title">Lastest Version:</span><span className="content">{object.last_version ? object.last_version : object.default_value['last_version']}</span></li>
                                <li><span className="title">Operating Systems:</span><span className="content">{object.os ? object.os : object.default_value['os']}</span></li>
                                <li><span className="title">Language:</span><span className="content">English &amp; other languages <i className="bi bi-info-circle tooltip_other_languages" /></span></li>
                                <li><span className="title">License:</span><span className="content"><span className="badge text-bg-success">Free</span></span></li>
                                { object.developer &&
                                <li><span className="title">Developer:</span><span className="content"><a href="#">{object.developer}</a></span></li>
                                }
                            </ul>
                            )}
                            { object.information_video &&
                            <div className="video ratio ratio-16x9 mb-4">
                                <iframe src={object.information_video_url} title="{ object.title }" allowFullScreen></iframe>
                            </div>
                            }
                            { !object.content ? (
                                <Skeleton count={100} />
                            ) : (
                            <div className="content" dangerouslySetInnerHTML={{__html: object.content }}></div>
                            )}
                        </div>
                        <div className="menu-tab-item tab-how-to-install" style={{ display: activeTab === 'how_to_install' ? 'block' : 'none' }}>
                            {object.how_to_install_video &&
                            <div className="video ratio ratio-16x9 mb-4">
                                <iframe src="{{ object.getVideo('how_to_install') }}" title="How to install {{ object.title }}" allowFullScreen></iframe>
                            </div>
                            }
                            <div dangerouslySetInnerHTML={{__html: object.how_to_install }}></div>
                        </div>
                        { object.content &&
                        <div className="d-flex align-items-center justify-content-center mt-5 mb-5">
                            <span onClick={() => downloadFile(object.download_url)} className={`${downloadClass} main_download_btn download-btn btn btn-primary btn-lg d-flex align-items-center justify-content-between`} title="">
                                <div className="text text-start">
                                    <span className="main-text">{ downloadText }</span>
                                    <span className="sub-text">{ object.download_count } downloads</span>
                                </div>
                                <div className="icon"><i className="bi bi-download"></i></div>
                            </span>
                        </div>
                        }
                        { object.related_articles &&
                            <div className="related-softs mt-5">
                                <h3>Related Softs</h3>
                                <div className="category-soft-list row gx-4 gy-4">
                                    {object.related_articles.map((item, idx) => (
                                    <div className="col-lg-6" key={idx}>
                                        <Link className="item download_btn" to={ item.link }>
                                            <div className="top">
                                                <img className="image" src={ item.avatar } alt={ item.title }/>
                                                <div className="info">
                                                    <h3 className="title">{item.title}</h3>
                                                    <span className="count">{item.download_count} downloads</span>
                                                </div>
                                            </div>
                                            <div className="bottom">
                                                <div className="description">{item.description}</div>
                                                <span className="action btn btn-primary btn-sm">View Details</span>
                                            </div>
                                        </Link>
                                    </div>
                                    ))}
                                </div>
                            </div>
                        }
                    </div>
                    <div className="col-lg-4 col-right">

                        {object.content ? (
                            <div>
                                <h4 className="">Popular Softs</h4>
                                <div className="category-soft-list row gy-4">
                                    {object.popular_articles.map((item, idx) => (
                                        <div className="col-lg-12" key={idx}>
                                            <Link title="" className="item download_btn" to={item.link}>
                                                <div className="top">
                                                    <img className="image" src={item.avatar} alt={item.title}/>
                                                    <div className="info">
                                                        <h3 className="title">{item.title}</h3>
                                                        <span className="count">{item.download_count} downloads</span>
                                                    </div>
                                                </div>
                                                <div className="bottom">
                                                    <div className="description">{item.description}</div>
                                                    <span className="action btn btn-primary btn-sm">View Details</span>
                                                </div>
                                            </Link>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ) : (
                            <div className="category-soft-list row gy-4">
                                <h4 className=""><Skeleton /></h4>
                                { articleItem() }
                                { articleItem() }
                                { articleItem() }
                                { articleItem() }
                                { articleItem() }
                            </div>
                        )
                        }
                    </div>
                </div>
            </section>
        </div>
    );
}
