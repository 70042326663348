import {Link, NavLink, useSearchParams} from 'react-router-dom';
import {HashLink, NavHashLink} from 'react-router-hash-link';
import {useEffect, useState} from "react";
import Config from "../config";

export default function Navbar() {
    const [categories, setCategories] = useState([]);
    const [searchParams] = useSearchParams();

    useEffect(() => {
        fetch(Config.apiDomain + "category/getlist")
            .then((res) => res.json())
            .then((json) => {
                setCategories(json.data);
            });
    }, []);

    return (
        <header id="header" className="fixed-header">
            <nav className="navbar navbar-expand-lg">
                <div className="container">
                    <a className="navbar-brand" id="logo" href='/'>
                        <img src="/assets/images/misc/logov2.png?v=1" alt=""/>
                    </a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto ms-auto">
                            <li className="nav-item">
                                <a href="/" className="nav-link">Home</a>
                            </li>
                            <li className="nav-item dropdown">
                                <span className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Category
                                </span>
                                <ul className="dropdown-menu">
                                    {categories.map((category, key) => (
                                        <NavLink to={category.link} key={key} className="nav-link">{category.title}</NavLink>
                                    ))}
                                </ul>
                            </li>
                            <li className="nav-item">
                                <HashLink to="/#home-features" smooth className={"nav-link"}>Features</HashLink>
                            </li>
                            <li className="nav-item">
                                <HashLink to="/#home-faq" smooth className={"nav-link"}>FAQ</HashLink>
                            </li>
                        </ul>
                        <form className="d-flex search-form" role="search" method="get" action="/search">
                            <input className="form-control" type="text" name="q" defaultValue={searchParams.get("q")} placeholder="Search"/>
                            <button type="submit" className="icon"><i className="bi bi-search"></i></button>
                        </form>
                    </div>
                </div>
            </nav>
        </header>
    );
}
