import {useEffect, useState} from "react";
import Config from '../config.js';
import {Link} from "react-router-dom";

export default function Home() {
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        fetch(Config.apiDomain + "home")
            .then((res) => res.json())
            .then((json) => {
                setCategories(json.data);
            });

        document.title = 'Download Softwares for Windows';
    }, []);

    return (
        <div>
            <section className="section" id="home-header">
                <div className="container">
                    <div className="d-flex align-items-center">
                        <div className="col-left">
                        <span className="note note-primary">
                            <span className="badge text-bg-primary">NEW</span> The best programs for everyone
                        </span>
                            <h1>Download popular and useful softwares for your PC</h1>
                            <p className="caption">
                                Download your favorite software completely FREE<br/>without registration!
                            </p>
                            <div className="action-btn">
                                <a href="#home-catalog" className="btn btn-primary mb-2 me-3">Go to catalog <i className="bi bi-chevron-right"></i></a>
                            </div>
                            <div className="features d-flex">
                                <div className="item">
                                    <div className="image"><img src="/assets/images/icons/icon-file.svg"/></div>
                                    <h4 className="title">Fast Download</h4>
                                    <div className="caption">Download with high speed CDN Server</div>
                                </div>
                                <div className="item">
                                    <div className="image"><img src="/assets/images/icons/icon-cloud.svg"/></div>
                                    <h4 className="title">More Programs</h4>
                                    <div className="caption">Many useful programs in our category</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-right">
                            <img src="/assets/images/misc/soft-list-1.png" alt="Blue Softs"/>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section pb-2" id="home-catalog">
                <div className="container">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="col-left">
                            <h2 className="section-title text-start">
                                The best programs<br/>
                                by <span className="underlines">Blue Softs</span>
                            </h2>
                            <p className="section-caption text-start">
                                Choose, download and enjoy programs completely free now!
                            </p>
                        </div>
                        <div className="col-right">
                            <img src="/assets/images/misc/soft-list-2.png" alt=""/>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        {categories.map((category, key) => (
                            <div key={key} className={key === 0 ? 'col-12 mb-5' : 'col-6 mb-5'}>
                                <h2 className="catalog-title">{category.title}</h2>
                                <div className="catalog-soft-list">
                                    {category.article && category.article.map((item, idx) => (
                                        <div className="item" key={idx}>
                                            <div className="details">
                                                <div className="image"><Link to={item.link}><img src={item.avatar} alt={item.title}/></Link></div>
                                                <div className="info">
                                                    <Link to={item.link}><h3 className="title">{item.title}</h3></Link>
                                                    <p className="description">{item.description}</p>
                                                </div>
                                            </div>
                                            <div className="action">
                                                <Link to={item.link} className="download_btn btn btn-primary">Details</Link>
                                                <span className="count"> {item.download_count} downloads</span>
                                            </div>
                                        </div>
                                    ))}
                                    <div className="text-center mt-3 text-uppercase">
                                        <Link to={category.link}>View more <i className="bi bi-arrow-down-circle"></i></Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            <section className="section" id="home-features">
                <div className="container">
                    <h2 className="section-title">
                        Why you should download<br/>
                        softwares from <span className="underlines">Blue Softs?</span>
                    </h2>
                    <p className="section-caption">
                        All software has been carefully selected and evaluated by us, you can use it with confidence.
                    </p>
                    <div className="features-list custom-card custom-card-primary position-relative">
                        <div className="d-flex justify-content-between">
                            <div className="col-left">
                                <div className="item margin-item d-flex flex-wrap flex-column">
                                    <div className="title d-flex flex-wrap align-items-center">
                                        <img src="/assets/images/icons/icon-cloud-2.svg" alt=""/>
                                        <h4 className="text">Free</h4>
                                    </div>
                                    <div className="caption">
                                        On our site there are a lot of<br/>
                                        useful programs that you can<br/>
                                        download for free
                                    </div>
                                </div>

                                <div className="item d-flex flex-wrap flex-column">
                                    <div className="title d-flex flex-wrap align-items-center">
                                        <img src="/assets/images/icons/icon-cloud-2.svg" alt=""/>
                                        <h4 className="text">Fast</h4>
                                    </div>
                                    <div className="caption">
                                        You can download programs<br/>
                                        absolutely free and at high speed<br/>
                                        from anywhere in the world
                                    </div>
                                </div>
                            </div>
                            <div className="col-right">
                                <div className="item margin-item d-flex flex-wrap flex-column">
                                    <div className="title d-flex flex-wrap align-items-center justify-content-end">
                                        <img src="/assets/images/icons/icon-cloud-2.svg" alt=""/>
                                        <h4 className="text">Large</h4>
                                    </div>
                                    <div className="caption text-end">
                                        Our category of programs<br/>
                                        contains a large number of<br/>
                                        programs for download
                                    </div>
                                </div>

                                <div className="item d-flex flex-wrap flex-column">
                                    <div className="title d-flex flex-wrap align-items-center justify-content-end">
                                        <img src="/assets/images/icons/icon-cloud-2.svg" alt=""/>
                                        <h4 className="text">Trust</h4>
                                    </div>
                                    <div className="caption text-end">
                                        We are trusted by many users<br/>
                                        from all over the world and<br/>
                                        we value our reputation
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section" id="home-faq">
                <div className="container">
                    <h2 className="section-title">
                        Frequently Asked <span className="underlines">Questions</span>
                    </h2>
                    <p className="section-caption">
                        Answers to common questions regarding our website.
                    </p>
                    <div className="faq-block custom-card custom-card-primary position-relative">
                        <div className="icon-top-left position-absolute"><img src="/assets/images/misc/faq-top-left.png" alt=""/></div>
                        <div className="icon-top-right position-absolute"><img src="/assets/images/misc/faq-top-right.png" alt=""/></div>
                        <div className="accordion accordion-custom accordion-flush" id="home-faq-list">
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                        Is it true that all the programs on the site are free?
                                    </button>
                                </h2>
                                <div id="flush-collapseOne" className="accordion-collapse collapse" data-bs-parent="#home-faq-list">
                                    <div className="accordion-body">
                                        We provide an opportunity to download all programs absolutely free - without SMS and registration as well as at high speed.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                        What programs are on the site?
                                    </button>
                                </h2>
                                <div id="flush-collapseTwo" className="accordion-collapse collapse" data-bs-parent="#home-faq-list">
                                    <div className="accordion-body">
                                        Our site features programs in a wide variety of categories - from Multimedia to Business.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                        How can I download programs?
                                    </button>
                                </h2>
                                <div id="flush-collapseThree" className="accordion-collapse collapse" data-bs-parent="#home-faq-list">
                                    <div className="accordion-body">
                                        You can download any desired program from our website by clicking on the "Download" button.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                        What is the download speed?
                                    </button>
                                </h2>
                                <div id="flush-collapseFour" className="accordion-collapse collapse" data-bs-parent="#home-faq-list">
                                    <div className="accordion-body">
                                        We use a content delivery network (CDN) server around the world - so you will get the fastest download speed!
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
